<template>
  <div>
    <TooltipButton>
      <template v-slot:content>
        <BaseButton
          variant="salmon"
          size="standard"
          @click="showModal = true"
        >
          <template slot="iconMiddle">
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5"
            >
              <Bin fill="none"/>
            </Icon>
          </template>
        </BaseButton>
      </template>
      <template v-slot:tooltip>
        Delete Candidate
      </template>
    </TooltipButton>

    <Modal
      :open="showModal"
      @close="attemptClose()"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Delete candidate
          </h3>
          <button
            v-if="!isProcessing"
            class="text-gray-500 focus:outline-none"
            @click="showModal = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>
      <div
        v-if="!isProcessing"
        class="m-6"
      >
        <template v-if="candidates.length === 1">
          <p>Are you sure you want to delete {{ candidateName }}?</p>

          <Notice
            class="my-6"
            variant="info"
          >
            Deleting a candidate is GDPR-compliant. {{ candidateName }}’s personal data and assessment records will be permanently removed.
          </Notice>
        </template>

        <template v-if="candidates.length > 1">
          <p>Are you sure you want to delete these candidates?</p>

          <Notice
            class="my-6"
            variant="info"
          >
            Deleting Candidates is GDPR-compliant. Candidates’ personal data and assessment records will be permanently removed.
          </Notice>
        </template>

        <div class="flex justify-between my-6">
          <BaseButton
            variant="gray"
            @click="showModal = false"
          >
            No, cancel
          </BaseButton>
          <BaseButton
            variant="danger"
            @click="deleteCandidate()"
          >
            Yes, delete
          </BaseButton>
        </div>
      </div>
      <div
        v-else
        class="my-16"
      >
        <Loader />
      </div>
    </Modal>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Bin from '@components/Icons/Bin'
import Loader from '@components/Loader'
import Modal from '@components/Modal'
import Notice from '@components/Notice'
import Plus from '@components/Icons/Plus'
import TooltipButton from '@components/TooltipButton'

import organisationCandidatesApi from '@api/organisationCandidates'
import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    Loader,
    Modal,
    Notice,
    Plus,
    Bin,
    TooltipButton
  },

  props: {
    candidates: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isProcessing: false,
      showModal: false
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    }),

    /**
     * @return {String}
     */
    candidateName() {
      if (this.candidates.length === 1) {
        if (this.candidates[0].name) {
          return this.candidates[0].name
        }

        let name = [this.candidates[0].firstName]
        if (this.candidates[0].surname) {
          name.push(this.candidates[0].surname)
        }

        return name.join(' ')
      }
    }
  },

  methods: {
    /**
     * Attempt to close the modal
     */
    attemptClose() {
      if (this.isProcessing) {
        return // Cannot close while processing
      }
      this.showModal = false
    },

    /**
     * Carry out deletion
     */
    deleteCandidate() {
      this.isProcessing = true

      const candidateIds = this.candidates.map(candidate => candidate.id)

      return organisationCandidatesApi
        .deleteCandidates(this.organisationId, candidateIds)
        .then(() => {
          this.showModal = false
          this.$emit('candidates-deleted')
          // The user may have been attached to a job, so once a candidate
          // has been deleted, refresh the jobs to get up-to-date numbers

          // return this.$store.dispatch('jobs/getJobs')
          //   .then(() => {
          //     this.$emit('candidate-deleted')
          //   })
        })
    }
  }
}
</script>
