<template>
  <OnClickOutside :do="close">
    <div
      class="relative w-2/3 sm:w-1/3"
      :class="{ 'border-b-0 shadow': isOpen }"
    >
      <div class="flex items-center relative">
        <button
          type="button"
          class="form-input w-full text-left"
          @click="open"
        >
          <span v-if="value !== null">{{ value }}</span>
          <span
            v-else
            class="text-gray-500"
          >
            {{ placeholder }}
          </span>
        </button>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
          <svg
            class="fill-current h-4 w-4"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
          </svg>
        </div>
      </div>

      <div
        v-show="isOpen"
        class="bg-white absolute left-0 w-full shadow border border-t-0 py-3 pl-3 pr-1"
      >
        <ul class="overflow-y-auto max-h-dropdown">
          <li
            class="p-1 cursor-pointer hover:bg-gray-200"
            @click="clearFilter()"
          >
            All
          </li>
          <li
            v-for="(option, index) in options"
            :key="index"
            class="p-1 cursor-pointer hover:bg-gray-200"
            @click="select(option)"
          >
            {{ truncateString(option.name, 40) }}
          </li>
        </ul>
      </div>
    </div>
  </OnClickOutside>
</template>

<script>
import OnClickOutside from '@components/OnClickOutside'
import { truncateString } from '@utils/truncate'

export default {
  components: {
    OnClickOutside
  },

  props: {
    placeholder: {
      type: String,
      default: 'Select'
    },
    options: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      truncateString,
      isOpen: false,
      value: null
    }
  },

  methods: {
    open() {
      this.isOpen = true
    },

    close() {
      this.isOpen = false
    },

    select(option) {
      this.value = option.name
      this.$emit('selected', { name: this.value, id: option.uuid })
      this.close()
    },

    clearFilter() {
      this.value = null
      this.$emit('selected', { name: this.value, id: null })
      this.close()
    }
  }
}
</script>
