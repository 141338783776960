<template>
  <div :class="['space-y-8', { 'bg-gray-100' : isModal }]">
    <p class="mb-4 text-sm">
      Copy and paste to all candidates applying for this job:
    </p>
    <div class="flex items-center">
      <div class="relative w-full bg-white border border-gray-300">
        <input
          v-model="assessmentsLink"
          type="text"
          disabled
          class="w-full text-sm border-0 form-input pointer-none"
        >
        <button
          class="absolute inset-y-0 right-0 flex inline-flex space-x-2 items-center h-full p-0 px-4 duration-150 border rounded-tr rounded-br shadow-none focus:outline-none bg-secondary hover:bg-secondary-400 border-secondary"
          @click="copyAssessmentsLink()"
        >
          <svg
            viewBox="0 0 24 24"
            class="w-6 h-6"
          ><rect
            width="14"
            height="14"
            x="3"
            y="3"
            fill="none"
            stroke="#FFF"
            stroke-width="1"
          ></rect><rect
            width="14"
            height="14"
            x="7"
            y="7"
            fill="none"
            stroke="#FFF"
            stroke-width="1"
          ></rect></svg>
          <span class="text-sm text-white">Copy</span>
        </button>
      </div>
      <transition
        enter-active-class="transition-all duration-150 ease-out"
        leave-active-class="transition-all duration-150 ease-in"
        enter-class="opacity-0"
        leave-class="opacity-100"
        enter-to-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <span
          v-if="assessmentsLinkMessage"
          class="ml-2 text-xs text-gray-700"
        >{{ assessmentsLinkMessage }}</span>
      </transition>
    </div>

    <div>
      <p class="mb-4 text-sm">
        Or manually add candidates using our
        <router-link
          :to="{ name: 'bulk-invite', params: { job: job.uuid } }"
          class="underline"
        >
          candidate uploader
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

export default {
  props: {
    job: {
      type: Object,
      default: null
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      assessmentsLinkMessage: null
    }
  },

  computed: {
    /**
     * @return {string}
     */
    assessmentsLink() {
      if (!this.job.wizardSlug) {
        return null
      }
      return `${process.env.VUE_APP_SPLASH_URL}/ca/sign-up/${this.job.wizardSlug}`
    }
  },

  methods: {
    copyAssessmentsLink() {
      this.$copyText(this.assessmentsLink)
        .then(e => {
          this.assessmentsLinkMessage = 'Copied'
        })
        .catch(error => {
          console.log(error)
          this.assessmentsLinkMessage = 'Whoops! Something went wrong'
        })
    }
  }
}
</script>
