<template>
  <div>
    <form
      v-if="showSearch"
    >
      <div class="form-input relative w-2/3 sm:w-1/3 mb-6 flex items-center">
        <Icon
          width="24px"
          height="24px"
          view-box="0 0 24 24"
          class="text-gray-500"
        >
          <Search />
        </Icon>
        <input
          v-model="searchQuery"
          type="text"
          name="query"
          class="w-full focus:outline-none ml-3"
          placeholder="Search candidates"
        >
      </div>
    </form>

    <table class="w-full border-collapse text-gray-800">
      <thead>
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
            :class="['uppercase font-normal text-gray-500 text-left text-xs pb-2 px-6 sm:table-cell', [ responsiveColumns(column.key) ? 'table-cell' : 'hidden' ]]"
          >
            <a
              href="javascript:;"
              :class="['flex items-center', { 'font-semibold text-gray-600': column.sortable && (column.key === sortKey && sortOrder === 'asc') }]"
              @click="sortBy(column.key)"
            >
              <span>{{ column.key }}</span>

              <div
                v-if="column.sortable"
                class="px-2 text-gray-500"
              >
                <svg
                  :class="['fill-current h-4 w-4', { 'transform rotate-180 text-gray-600': column.key === sortKey && sortOrder === 'asc' }]"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>
            </a>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(data, key) in filteredData"
          :key="key"
        >
          <TableCell>
            <CandidateProfileLink
              v-if="activeTab === 'allCandidates'"
              :candidate-id="data.id"
              class="hover:text-secondary duration-300"
            >
              <Icon
                width="24px"
                height="24px"
                view-box="0 0 512 512"
                class="mr-4 text-gray-600"
              >
                <CandidateIcon />
              </Icon>
              {{ data.name }}
            </CandidateProfileLink>
            <p
              v-else
            >
              <Icon
                width="24px"
                height="24px"
                view-box="0 0 512 512"
                class="mr-4 text-gray-600"
              >
                <CandidateIcon />
              </Icon>
              {{ data.name }}
            </p>
          </TableCell>

          <TableCell class="hidden sm:table-cell">
            {{ data.email }}
          </TableCell>

          <TableCell v-if="data.createdAt">
            {{ formatDate(data.createdAt) }}
          </TableCell>

          <TableCell v-else-if="data.invitationSentAt">
            {{ formatDate(data.invitationSentAt) }}
          </TableCell>

          <TableCell v-else>
            Date
          </TableCell>
        </tr>
      </tbody>
    </table>

    <Pagination
      class="mt-4"
      :total-pages="paginationData.totalPages"
      :per-page="paginationData.perPage"
      :current-page="paginationData.currentPage"
      @pagechanged="$emit('pagechanged', $event)"
    />
  </div>
</template>

<script>
import CandidateProfileLink from '@components/Candidates/CandidateProfileLink'
import Icon from '@components/Icons/Icon'
import CandidateIcon from '@components/Icons/Candidate'
import Pagination from '@components/Pagination'
import Search from '@components/Icons/Search'
import TableCell from '@components/TableCell'

import { truncateString } from '@utils/truncate'
import { formatDate } from '@utils/formatDate'

export default {
  components: {
    CandidateProfileLink,
    Icon,
    CandidateIcon,
    Pagination,
    Search,
    TableCell
  },

  props: {
    columns: {
      type: Array,
      default: null
    },
    tableData: {
      type: Array,
      default: null
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    filter: {
      type: String,
      default: ''
    },
    paginationData: {
      type: Object,
      default: null
    },
    sortData: {
      type: Object,
      default: null
    },
    activeTab: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      formatDate,
      truncateString,

      searchQuery: '',
      sortKey: 'createdAt',
      sortOrder: 'desc'
    }
  },

  computed: {
    /**
     * Rename the keys to match the column headings
     *
     * @return {Array}
     */
    filteredData() {
      return this.tableData
        .map(({ id, firstName, surname, email, jobs, createdAt, invitationSentAt, sentExams }) => {
          let jobNames
          if (jobs && jobs.length > 0) {
            jobNames = jobs.map(job => job.name).join(', ')
          }
          return {
            id,
            name: `${firstName} ${surname}`,
            email,
            jobNames,
            createdAt,
            invitationSentAt,
            sentExams
          }
        })
    }
  },

  created() {
    if (this.sortData) {
      this.sortKey = this.sortData.key
      this.sortOrder = this.sortData.order
    }
  },

  methods: {
    /**
     * Trigger sort by key
     *
     * @param {string} key
     */
    sortBy(key) {
      if (this.sortOrder === 'asc') {
        this.sortOrder = 'desc'
      } else {
        this.sortOrder = 'asc'
      }

      this.$emit('sort', { key, order: this.sortOrder })
    },

    setScoreData(data, slug) {
      const foundationSlug = `foundation-${slug}`
      const attempt = data.attempts.find(attempt => attempt.examSlug === slug || attempt.examSlug === foundationSlug)

      if (!attempt) {
        return '-'
      }

      if (attempt.examType === 'personality') {
        return attempt.result
      } else {
        return attempt.scorePercentage + '%'
      }
    },

    /**
     * Display column headings based on viewport
     */
    responsiveColumns(column) {
      const smallScreenColumns = [
        'name',
        'job'
      ]
      return smallScreenColumns.includes(column)
    }
  }
}
</script>
