<template>
  <div>
    <BaseButton
      v-if="$usesFeature('candidate-wizard')"
      @click="modalOpen = true"
    >
      Invite Candidates
    </BaseButton>

    <BaseButton
      v-if="$usesFeature('classic-jobs')"
      :to="{ name: 'bulk-invite', params: { job: job.uuid } }"
    >
      Add Candidates
    </BaseButton>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div
        class="bg-white border-b-2"
      >
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Invite Candidates
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <InviteCandidatesModalContent
        v-if="job"
        :job="job"
        class="p-6"
        is-modal
      />
      <InviteCandidatesWithoutJobContent
        v-else
        class="p-6"
        is-modal
      />
    </Modal>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Modal from '@components/Modal'
import Plus from '@components/Icons/Plus'
import InviteCandidatesModalContent from '@components/Candidates/InviteCandidatesModalContent'
import InviteCandidatesWithoutJobContent from '@components/Candidates/InviteCandidatesWithoutJobContent'

import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

export default {
  components: {
    Icon,
    Modal,
    Plus,
    InviteCandidatesModalContent,
    InviteCandidatesWithoutJobContent
  },

  props: {
    job: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      modalOpen: false
    }
  }
}
</script>
