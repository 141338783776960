<template>
  <div class="w-full mx-auto py-8">
    <CandidateList v-if="$usesFeature('classic-jobs')" />
    <CandidatesList v-if="$usesFeature('candidate-wizard')" />
  </div>
</template>

<script>
import CandidateList from '@components/ClientCandidates/CandidateList'
import CandidatesList from '@components/Candidates/CandidatesList'

export default {
  page: {
    title: 'Candidates'
  },

  components: {
    CandidateList,
    CandidatesList
  }
}
</script>
