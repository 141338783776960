<template>
  <div>
    <header>
      <ul class="flex flex-grow max-w-screen-xl mx-auto">
        <li
          v-for="tab in tabs"
          :key="tab"
          class="block mr-8 last:mr-0"
        >
          <a
            href="javascript:;"
            class="block py-4 text-gray-500 text-sm border-b-2 -mb-px-2"
            :class="{
              'border-secondary text-gray-800': activeTab === tab
            }"
            @click="switchTab(tab)"
          >
            <slot :name="tabHeadSlotName(tab)">
              {{ tab }}
            </slot>
          </a>
        </li>
      </ul>
    </header>

    <div>
      <slot :name="tabPanelSlotName" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    initialTab: {
      type: String,
      default: ''
    },
    tabs: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      activeTab: this.initialTab
    }
  },

  computed: {
    tabPanelSlotName() {
      return `tab-panel-${this.activeTab}`
    }
  },

  methods: {
    tabHeadSlotName(tabName) {
      return `tab-head-${tabName}`
    },

    switchTab(tabName) {
      this.activeTab = tabName
      this.$emit('activeTab', tabName)
    }
  }
}
</script>
