<template>
  <div :class="['space-y-8', { 'bg-gray-100' : isModal }]">
    <p class="mb-4 text-sm">
      Add candidates to your organisation’s talent pool by sending them the link below:
    </p>
    <div class="flex items-center">
      <div class="relative bg-white border border-gray-300 w-full">
        <input
          v-model="assessmentsLink"
          type="text"
          disabled
          class="form-input text-sm w-full border-0 pointer-none"
        >
        <button
          class="focus:outline-none shadow-none rounded-tr rounded-br p-0 bg-secondary hover:bg-secondary-400 duration-150 h-full inline-flex absolute inset-y-0 right-0 flex items-center px-4 border border-secondary"
          @click="copyAssessmentsLink()"
        >
          <svg
            viewBox="0 0 24 24"
            class="w-6 h-6"
          ><rect
            width="14"
            height="14"
            x="3"
            y="3"
            fill="none"
            stroke="#FFF"
            stroke-width="1"
          ></rect><rect
            width="14"
            height="14"
            x="7"
            y="7"
            fill="none"
            stroke="#FFF"
            stroke-width="1"
          ></rect></svg>
        </button>
      </div>
      <transition
        enter-active-class="transition-all duration-150 ease-out"
        leave-active-class="transition-all duration-150 ease-in"
        enter-class="opacity-0"
        leave-class="opacity-100"
        enter-to-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <span
          v-if="assessmentsLinkMessage"
          class="text-xs text-gray-700 ml-2"
        >{{ assessmentsLinkMessage }}</span>
      </transition>
    </div>

    <div>
      <p class="mb-4 text-sm">
        Or manually invite candidates via email:
      </p>
      <router-link
        :to="{ name: 'bulk-invite-no-job' }"
        class="text-secondary duration-150 ease-out hover:text-green-500"
      >
        <span>Invite Candidates</span>
        <Icon
          view-box="0 0 24 24"
          class="w-5 h-5 fill-none stroke-current ml-1"
        >
          <Send
            stroke-width="1.5"
          />
        </Icon>
      </router-link>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@components/Icons/Icon'
import Send from '@components/Icons/Send'

import { mapGetters } from 'vuex'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

export default {
  components: {
    Icon,
    Send
  },

  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      assessmentsLinkMessage: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    }),

    /**
     * @return {string}
     */
    assessmentsLink() {
      return `${process.env.VUE_APP_SPLASH_URL}/ca/sign-up?organisation=${this.organisationId}`
    }
  },

  methods: {
    copyAssessmentsLink() {
      this.$copyText(this.assessmentsLink)
        .then(e => {
          this.assessmentsLinkMessage = 'Copied'
        })
        .catch(error => {
          console.log(error)
          this.assessmentsLinkMessage = 'Whoops! Something went wrong'
        })
    }
  }
}
</script>
